/**
 * @generated SignedSource<<d8fe8a3f33b983e79ccfbd4bba598a7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksUseDeepLinkTagsBase$data = {
  readonly __typename: string;
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentType": "hooksUseDeepLinkTagsBase";
};
export type hooksUseDeepLinkTagsBase$key = {
  readonly " $data"?: hooksUseDeepLinkTagsBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseDeepLinkTagsBase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksUseDeepLinkTagsBase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "Base",
  "abstractKey": "__isBase"
};

(node as any).hash = "7b69533277b41bdea2e55b36564f11fe";

export default node;
