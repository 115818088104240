import { notEmpty } from "@product/scmp-sdk";
import { useAsync, useMountEffect, useSyncedRef } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { useEffect, useMemo } from "react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import type { YouTubePlayer } from "youtube-player/dist/types";

import { onlyOnePlayingPlayerAtom } from "~/components/article/article-list/hooks";
import type { VideoPlayMode } from "~/components/scmp-youtube-video/enums";
import { data as appData, config } from "~/data";
import type { scmpYoutubeVideoPlayerQuery } from "~/queries/__generated__/scmpYoutubeVideoPlayerQuery.graphql";

import { getPlayerOptions } from "./helpers";
import {
  useAdIntegration,
  useCheckManualPauseEffect,
  useIntersectionHandler,
  useYouTubeControl,
} from "./hooks";
import {
  AdContainer,
  AdControlButtonContainer,
  Container,
  StyledMuteIcon,
  StyledUnmuteIcon,
  StyledYouTube,
  VideoContainer,
} from "./styles";

export type Props = {
  adInfo?: ADInfo;
  allow?: string;
  allowfullscreen?: string;
  className?: string;
  enableAutoPlay: boolean;
  entityId?: string;
  frameborder?: string;
  gaActionPrefix?:
    | `Youtube ${"article body" | "article header" | "article footer" | "footer widget"}/`
    | "";
  height?: string;
  index: number;
  isEnabledImaManager?: boolean;
  isMuteVideo?: boolean;
  relatedChannels?: string[];
  title?: string;
  videoId: string;
  videoPlayMode: VideoPlayMode;
  width?: string;
};

export const SCMPYouTubeVideoPlayer: FunctionComponent<Props> = ({
  adInfo,
  className,
  enableAutoPlay,
  entityId,
  gaActionPrefix = "Youtube article body/",
  height,
  index,
  isEnabledImaManager = true,
  isMuteVideo = true,
  relatedChannels = [appData.youtube.scmpChannelId],
  title,
  videoId,
  width,
}) => {
  const environment = useRelayEnvironment();
  const [{ result: data }, { execute }] = useAsync(() =>
    fetchQuery<scmpYoutubeVideoPlayerQuery>(
      environment,
      graphql`
        query scmpYoutubeVideoPlayerQuery($applicationId: String!, $entityId: String!) {
          content(
            contentType: VIDEO
            filter: { entityId: $entityId, applicationId: $applicationId }
          ) {
            headline
            sections {
              value {
                name
              }
            }
          }
        }
      `,
      {
        applicationId: appData.application.scmp.entityUuid,
        entityId: entityId ?? "",
      },
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise(),
  );

  useMountEffect(() => {
    if (!notEmpty(entityId)) return;
    void execute();
  });

  // Can play same video in different places
  const uniqueVideoId = ["yt", videoId, entityId, index].filter(notEmpty).join("-");
  const adElementId = `ad-${uniqueVideoId}`;

  const trackingData = useMemo(
    () => ({
      gaActionPrefix,
      sectionString:
        first(data?.content?.sections)
          ?.value?.map(section => section?.name)
          .join("|") ?? "",
      youTubeVideoId: videoId,
      youTubeVideoTitle: title ?? "",
    }),
    [data?.content?.sections, gaActionPrefix, title, videoId],
  );

  const { handleOnEnd, handleOnReady, handleOnStateChange, player } = useYouTubeControl(
    uniqueVideoId,
    trackingData,
  );

  const zone = adInfo?.zone ?? `video/article/instream1`;
  const adIu = `${config.advertisement.googlePublisherTag.networkId}/${config.advertisement.googlePublisherTag.adUnitPrefix}/${zone}`;

  const { stopYoutubeAutoplay } = useAtomValue(onlyOnePlayingPlayerAtom);
  const wrappedEnableAutoplay = useMemo(
    () => enableAutoPlay && !stopYoutubeAutoplay,
    [enableAutoPlay, stopYoutubeAutoplay],
  );

  const {
    adsManager,
    handleChangeVolume,
    initImaManager,
    isAdPlaying,
    isAdStarted,
    isImaInitialized,
    isMute,
  } = useAdIntegration({
    adData: {
      adInfo,
      iu: adIu,
    },
    player,
    trackingData,
  });

  const {
    captureIntersectionElement,
    handleIsIntersecting,
    handleIsNotIntersecting,
    intersection,
  } = useIntersectionHandler({
    adsManager,
    isAdPlaying,
    isAdStarted,
    player,
  });

  const {
    handleOnYoutubeInteractEnd,
    handleOnYoutubeInteractStart,
    handleOnYoutubePlayerStateChange: handleManualPauseEffectOnStateChange,
    isManualPaused,
  } = useCheckManualPauseEffect({
    adsManager,
    isAdStarted,
  });

  const latestIsManualPausedReference = useSyncedRef(isManualPaused);
  useEffect(() => {
    const isIntersectionAndAdAndPlayerReady = intersection && isImaInitialized && player;
    if (!isIntersectionAndAdAndPlayerReady) {
      return;
    }

    if (intersection.isIntersecting) {
      const shouldTriggerIntersect =
        wrappedEnableAutoplay && !latestIsManualPausedReference.current;
      shouldTriggerIntersect && handleIsIntersecting();
    } else {
      handleIsNotIntersecting();
    }
  }, [
    handleIsIntersecting,
    handleIsNotIntersecting,
    intersection,
    isImaInitialized,
    latestIsManualPausedReference,
    player,
    wrappedEnableAutoplay,
  ]);

  return (
    <Container className={className}>
      <VideoContainer
        ref={captureIntersectionElement}
        onMouseLeave={handleOnYoutubeInteractEnd}
        onMouseOver={handleOnYoutubeInteractStart}
        onTouchEnd={handleOnYoutubeInteractEnd}
        onTouchStart={handleOnYoutubeInteractStart}
      >
        <StyledYouTube
          id={uniqueVideoId}
          opts={getPlayerOptions({
            adIu,
            enableAutoPlay: wrappedEnableAutoplay,
            height,
            relatedChannels,
            width,
          })}
          title={data?.content?.headline ?? ""}
          videoId={videoId}
          onEnd={handleOnEnd}
          onReady={(event: { target: YouTubePlayer }) => {
            isMuteVideo && void event.target.mute();
            isEnabledImaManager && initImaManager(event.target, uniqueVideoId, adElementId);
            handleOnReady(event);
          }}
          onStateChange={(event: { data: number }) => {
            handleOnStateChange(event);
            handleManualPauseEffectOnStateChange(event);
          }}
        />
        <AdContainer id={adElementId} />
        {isAdStarted && (
          <AdControlButtonContainer>
            {isMute ? (
              <StyledUnmuteIcon onClick={() => handleChangeVolume(false)} />
            ) : (
              <StyledMuteIcon onClick={() => handleChangeVolume(true)} />
            )}
          </AdControlButtonContainer>
        )}
      </VideoContainer>
    </Container>
  );
};

SCMPYouTubeVideoPlayer.displayName = "SCMPYouTubeVideoPlayer";
