import { useIntervalEffect } from "@react-hookz/web";
import YouTube from "@vip30/react-youtube";
import { useAtom } from "jotai";
import { useCallback, useState } from "react";
import type { YouTubePlayer } from "youtube-player/dist/types";

import { onlyOnePlayingPlayerAtom } from "~/components/article/article-list/hooks";
import { pushPlayer as chartbeatPushPlayer } from "~/components/tracking/chartbeat";
import { sendVideoTracking } from "~/components/tracking/google-tag-manager/apis";
import { add as mirrorAdd, load as mirrorLoad } from "~/components/tracking/mirror";
import { sendOnePlusXEvent } from "~/components/tracking/one-plus-x";

import type { TrackingData } from "./types";

/**
 * For supporting youtube player control
 */
export const useYouTubeControl = (uniqueVideoId: string, trackingData: TrackingData) => {
  const [player, setPlayer] = useState<OrUndefined<YouTubePlayer>>();
  const [{ currentYoutubeVideoId }, setOnlyOnePlayingPlayer] = useAtom(onlyOnePlayingPlayerAtom);

  const [hasTriggeredThreeSecond, setHasTriggeredThreeSecond] = useState(false);

  const gaLabel = `${trackingData.youTubeVideoTitle} - ${trackingData.youTubeVideoId}`;
  const onePlusVideoVertical = trackingData.sectionString;

  useIntervalEffect(
    async () => {
      const currentTime = await player?.getCurrentTime();

      if (currentTime && currentTime > 3) {
        sendVideoTracking({
          action: `${trackingData.gaActionPrefix}Video progress 3s`,
          category: "Youtube video",
          label: gaLabel,
        });

        setHasTriggeredThreeSecond(true);
      }
    },
    currentYoutubeVideoId && !hasTriggeredThreeSecond ? 500 : undefined,
  );

  const [lastSeenPercentage, setLastSeenPercentage] = useState(0);

  useIntervalEffect(
    async () => {
      const durationOfVideo = await player?.getDuration();
      const currentTime = await player?.getCurrentTime();

      if (!currentTime || !durationOfVideo) return;

      const percentageElapsed = Math.round((currentTime / durationOfVideo) * 100);

      if (
        percentageElapsed > 0 &&
        percentageElapsed % 10 === 0 &&
        percentageElapsed !== lastSeenPercentage
      ) {
        sendVideoTracking({
          action: `${trackingData.gaActionPrefix}Video progress ${percentageElapsed}%`,
          category: "Youtube video",
          label: gaLabel,
        });

        sendOnePlusXEvent({
          action: `Video Progress ${percentageElapsed}%`,
          type: "video-plays",
          videoVertical: onePlusVideoVertical,
        });

        setLastSeenPercentage(percentageElapsed);
      }
    },
    currentYoutubeVideoId && lastSeenPercentage !== 100 ? 500 : undefined,
  );

  const handleOnEnd = useCallback(() => {
    setPlayer(undefined);
  }, []);

  const handleOnReady = useCallback((event: { target: YouTubePlayer }) => {
    setPlayer(event.target);
    chartbeatPushPlayer(event.target);
    mirrorAdd(event.target, "yt");
    mirrorLoad("", "ad");
  }, []);

  const handleOnStateChange = useCallback(
    ({ data }: { data: number }) => {
      switch (data) {
        case YouTube.PlayerState.PLAYING:
          if (uniqueVideoId !== currentYoutubeVideoId) {
            sendVideoTracking({
              action: `${trackingData.gaActionPrefix}Play`,
              category: "Youtube video",
              label: gaLabel,
            });

            sendOnePlusXEvent({
              action: "Play",
              type: "video-plays",
              videoVertical: onePlusVideoVertical,
            });
            setOnlyOnePlayingPlayer(previousState => ({
              ...previousState,
              currentYoutubeVideoId: uniqueVideoId,
              youtubePlayer: player,
            }));
          }

          break;
        case YouTube.PlayerState.PAUSED:
          if (uniqueVideoId === currentYoutubeVideoId) {
            sendVideoTracking({
              action: `${trackingData.gaActionPrefix}Pause`,
              category: "Youtube video",
              label: gaLabel,
            });
            setOnlyOnePlayingPlayer(previousState => ({
              ...previousState,
              currentYoutubeVideoId: undefined,
              youtubePlayer: undefined,
            }));
          }
          break;
        case YouTube.PlayerState.ENDED:
          // TODO: close
          break;
      }
    },
    [
      uniqueVideoId,
      currentYoutubeVideoId,
      trackingData.gaActionPrefix,
      gaLabel,
      onePlusVideoVertical,
      setOnlyOnePlayingPlayer,
      player,
    ],
  );

  return {
    handleOnEnd,
    handleOnReady,
    handleOnStateChange,
    player,
  };
};
