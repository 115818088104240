import { useBreakpoint, useWindow } from "@product/scmp-sdk";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { getGoogleAnalyticsClientId } from "~/components/tracking/google-analytics/apis";
import { useIpGeoLocation } from "~/lib/hooks";
import { formatInTimeZone } from "~/lib/utils";

import type { ContentType, EventCategory } from "./types";

export const useNewsletterWidget = ({
  contentType,
  eventCategory,
  section,
  userType,
}: {
  contentType: ContentType;
  eventCategory: EventCategory;
  section: string;
  userType?: string;
}) => {
  const { query } = useRouter();
  const geoLocation = useIpGeoLocation();
  const breakpoint = useBreakpoint();
  const window = useWindow();

  const [dataMatrix, setDataMatrix] = useState<Record<string, unknown>>();
  useEffect(() => {
    const run = async () => {
      const clientId = await getGoogleAnalyticsClientId();
      setDataMatrix({
        from: "scmp_web",
        metrics: {
          campaign: query?.utm_campaign,
          client_id: clientId,
          content: query?.utm_content,
          content_type: contentType,
          country: geoLocation?.result?.country,
          device_category: breakpoint,
          event_category: eventCategory,
          medium: query?.utm_medium,
          section,
          source: query?.utm_source,
          timestamp: formatInTimeZone(new Date(), "yyyy-MM-dd HH:mm"),
          url: window?.location?.href,
          user_type: userType,
        },
      });
    };
    void run();
  }, [
    breakpoint,
    contentType,
    eventCategory,
    geoLocation?.result?.country,
    query?.utm_campaign,
    query?.utm_content,
    query?.utm_medium,
    query?.utm_source,
    section,
    userType,
    window?.location?.href,
  ]);

  return {
    dataMatrix,
  };
};
