import { sendGA4GTMTracking } from "~/components/tracking/google-tag-manager/apis";

import type { Event, UntypedEvent } from "./types";

export function sendGA4Tracking<Untyped extends true | false = false>(
  parameters: Untyped extends true ? UntypedEvent : Event,
  options?: { untyped: Untyped },
) {
  sendGA4GTMTracking(
    {
      _clear: true,
      event: "GA4_tracking",
      ...parameters,
    },
    options,
  );
}
