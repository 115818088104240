import type { YouTubePlayer } from "youtube-player/dist/types";

declare global {
  interface Window {
    MIRROR?: {
      video?: {
        add(player: YouTubePlayer, name: string): void;
        load(adId: string, name: string): void;
      };
    };
  }
}

export const add = (player: YouTubePlayer, name: string) => {
  if (!window.MIRROR?.video) return;
  window.MIRROR?.video?.add(player, name);
};

export const load = (adId: string, name: string) => {
  if (!window.MIRROR?.video) return;
  window.MIRROR?.video?.load(adId, name);
};
