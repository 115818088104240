import { forwardRef } from "react";

import { BaseImage } from "~/components/common/base-image";

import { Variant } from "./enums";
import Spinner from "./spinner.gif";
import { CenteredContainer } from "./styles";
import WheelOnDarkGray from "./wheel-on-dark-gray.gif";
import WheelOnGray from "./wheel-on-gray.gif";
import WheelOnWhite from "./wheel-on-white.gif";

export type Props = {
  className?: string;
  color?: "gray" | "white" | "darkGray";
  variant?: Variant;
};

const determineWheelColor = (color: Props["color"]) => {
  switch (color) {
    case "gray":
      return WheelOnGray;
    case "white":
      return WheelOnWhite;
    case "darkGray":
      return WheelOnDarkGray;
    default:
      return WheelOnGray;
  }
};

export const Loading = forwardRef<HTMLImageElement, Props>(
  ({ className, color, variant = Variant.Wheel, ...attributes }, reference) => {
    const getSource = () => {
      switch (variant) {
        case Variant.Spinner:
          return Spinner;
        case Variant.Wheel:
          return determineWheelColor(color);
      }
      return null;
    };

    const source = getSource();

    return (
      <CenteredContainer className={className} {...attributes}>
        {source && (
          <BaseImage
            $aspectRatio="1"
            $background="none"
            $height="min-content"
            $width="min-content"
            alt={source.alt}
            aria-label="loading"
            lazyload={false}
            ref={reference}
            src={source.src}
            disableLinkingData
          />
        )}
      </CenteredContainer>
    );
  },
);

Loading.displayName = "Loading";
