import * as Sentry from "@sentry/browser";
import { useAtomValue, useSetAtom } from "jotai";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import type { YouTubePlayer } from "youtube-player/dist/types";

import { onlyOnePlayingPlayerAtom } from "~/components/article/article-list/hooks";
import type { TrackingData } from "~/components/scmp-youtube-video/scmp-youtube-video-player/hooks/types";
import { getGoogleAnalyticsClientIdSync } from "~/components/tracking/google-analytics/apis";
import { sendVideoTracking } from "~/components/tracking/google-tag-manager/apis";
import { accountAtom } from "~/lib/account";

declare namespace YT {
  class ImaManager {
    constructor(
      player: YouTubePlayer,
      elementId: string,
      adElementId: string,
      makeAdsRequest: (adsRequest: google.ima.AdsRequest) => void,
    );
    getAdsLoader: () => google.ima.AdsLoader;
  }
}

export type AdData = {
  adInfo?: ADInfo;
  iu: string;
};

export type UseImaIntegrationParameters = {
  adData: AdData;
  player: OrUndefined<YouTubePlayer>;
  trackingData: TrackingData;
};

export const useImaIntegration = ({
  adData,
  player,
  trackingData,
}: UseImaIntegrationParameters) => {
  const { user } = useAtomValue(accountAtom);
  const [isImaInitialized, setIsImaInitialized] = useState(false);

  const [adsManager, setAdsManager] = useState<google.ima.AdsManager>();
  const [adsLoader, setAdsLoader] = useState<google.ima.AdsLoader>();

  const makeAdsRequest = useCallback(
    (adsRequest: google.ima.AdsRequest) => {
      const getCustomizedParameter = () => {
        const customizedParameter: Record<string, string | number | unknown[] | undefined> = {
          ...adData?.adInfo?.targeting,
          ga_id: gaId,
          uuid: user?.uuid,
        };
        return qs.stringify(
          Object.entries(customizedParameter).reduce((accumulator, [key, value]) => {
            if (!value) return accumulator;
            accumulator[key] = Array.isArray(value) ? value.join(",") : `${value}`;
            return accumulator;
          }, {} as Record<string, string>),
        );
      };
      const adUrl = "https://pubads.g.doubleclick.net/gampad/ads";
      const gaId = getGoogleAnalyticsClientIdSync();
      // cspell: ignore instream gdfp tfcd unviewed vpmute
      adsRequest.adTagUrl = `${adUrl}?${qs.stringify({
        correlator: "",
        cust_params: getCustomizedParameter(),
        env: "instream",
        gdfp_req: "1",
        impl: "s",
        iu: adData.iu,
        npa: "0",
        output: "vast",
        sz: "400x300|480x70|480x360|480x361|640x480",
        tfcd: "0",
        unviewed_position_start: "1",
        url: window.location.origin,
        vpa: "click",
        vpmute: "1",
        wta: "1",
      })}`;
    },
    [adData.adInfo?.targeting, adData.iu, user?.uuid],
  );

  const initImaManager = useCallback(
    (player: YouTubePlayer, elementId: string, adElementId: string) => {
      if (!player) return;
      const handleConfigImaSdkSettings = () => {
        const imaSdkSettings = google.ima.settings;
        if (!imaSdkSettings) return;

        if (user?.uuid) imaSdkSettings.setPpid(user.uuid);
        else {
          const gaClientId = getGoogleAnalyticsClientIdSync();
          gaClientId && imaSdkSettings.setPpid(gaClientId);
        }
      };
      try {
        const imaManager = new YT.ImaManager(player, elementId, adElementId, makeAdsRequest);
        handleConfigImaSdkSettings();

        const adsLoader = imaManager.getAdsLoader();
        if (adsLoader) {
          setAdsLoader(adsLoader);
        } else {
          setIsImaInitialized(true);
        }
      } catch (error) {
        console.info("Error in IMA Initialization", error);
        setIsImaInitialized(true);
      }
    },
    [makeAdsRequest, user?.uuid],
  );

  const useImaAdManagerEventHandler = (player: OrUndefined<YouTubePlayer>) => {
    const [isAdStarted, setIsAdStarted] = useState(false);
    const [isAdPlaying, setIsAdPlaying] = useState(false);

    const setOnlyOnePlayingPlayer = useSetAtom(onlyOnePlayingPlayerAtom);

    const handleOnAdSkipped = useCallback(() => {
      setIsAdStarted(false);
      setIsAdPlaying(false);
    }, []);

    const handleOnAdStarted = useCallback((event: google.ima.AdEvent) => {
      setIsAdStarted(true);
      setIsAdPlaying(true);

      const ad = event.getAd();
      if (!ad) return;
      const adId = ad.getAdId();
      const creativeAdId = ad.getCreativeAdId();
      const adTimeOffset = ad.getAdPodInfo().getTimeOffset();

      sendVideoTracking({
        action: `${trackingData.gaActionPrefix}ad/${adTimeOffset}`,
        category: "Youtube video",
        label: `${trackingData.youTubeVideoId}/${adId}/${creativeAdId}`,
      });
    }, []);

    const handleOnAdPaused = useCallback(() => {
      setIsAdPlaying(false);
    }, []);

    const handleOnAdResumed = useCallback(() => {
      setIsAdPlaying(true);
    }, []);

    const handleOnAdsManagerLoaded = useCallback(
      async (adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent) => {
        if (!player) return;
        setIsImaInitialized(true);
        const currentTime = await player.getCurrentTime();
        const duration = await player.getDuration();
        const adsManager = adsManagerLoadedEvent.getAdsManager({
          currentTime,
          duration,
        });

        adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, handleOnAdStarted);
        adsManager.addEventListener(google.ima.AdEvent.Type.PAUSED, handleOnAdPaused);
        adsManager.addEventListener(google.ima.AdEvent.Type.RESUMED, handleOnAdResumed);
        adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, handleOnAdSkipped);
        adsManager.addEventListener(google.ima.AdEvent.Type.SKIPPED, handleOnAdSkipped);
        adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, handleOnAdSkipped);

        setAdsManager(adsManager);
        setOnlyOnePlayingPlayer(previousState => ({
          ...previousState,
          adsManager,
        }));
      },
      [
        handleOnAdPaused,
        handleOnAdResumed,
        handleOnAdSkipped,
        handleOnAdStarted,
        player,
        setOnlyOnePlayingPlayer,
      ],
    );

    const handleOnAdError = useCallback((adErrorEvent: google.ima.AdErrorEvent) => {
      setIsImaInitialized(true);
      Sentry.captureException(adErrorEvent.getError().getMessage());
    }, []);

    return {
      handleOnAdError,
      handleOnAdsManagerLoaded,
      isAdPlaying,
      isAdStarted,
    };
  };
  const { handleOnAdError, handleOnAdsManagerLoaded, isAdPlaying, isAdStarted } =
    useImaAdManagerEventHandler(player);

  useEffect(() => {
    if (!adsLoader) return;
    adsLoader.addEventListener(
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      handleOnAdsManagerLoaded,
    );
    adsLoader.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, handleOnAdError);
    return () => {
      adsLoader?.destroy();
    };
  }, [adsLoader, handleOnAdError, handleOnAdsManagerLoaded]);

  return {
    adsManager,
    initImaManager,
    isAdPlaying,
    /**
     * If we don't have advertisement or the advertisement finished, isAdStarted will be false
     */
    isAdStarted,
    isImaInitialized,
  };
};
