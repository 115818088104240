import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { SCMPYouTubeVideoFooter } from "./scmp-youtube-video-footer";

export const StyledSCMPYouTubeVideoFooter = styled(SCMPYouTubeVideoFooter)`
  ${props => {
    switch (props.variant) {
      case "default":
        return css`
          display: none;
          ${props.theme.breakpoints.up("tablet")} {
            display: flex;
          }
        `;
    }
  }}
`;

export const SCMPYoutubeVideoContainer = styled.div`
  inline-size: 100%;
  block-size: auto;

  aspect-ratio: 16 / 9;
`;
